$navy: #243746;
$cream: #f3f1e9;
$ivory: #f9f8f5;
$yellow-light: #fcd672;
$blue-mid: #009abf;
$blue-light: #73c3d5;
$accent-a: $yellow-light;
$accent-b: $blue-light;

html {
  background: $accent-a;
}

body {
  background: $ivory;
}

::selection {
  color: $navy;
  background: rgba($accent-a, 0.8);
}
