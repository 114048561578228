@use "./breakpoints";
@use "./colours";
@use "./typography";

$height: 135.008px; // Manually measured after layout.
$height-large: 85.016px; // Manually measured after layout.

.navbar {
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: colours.$navy;

  a {
    display: flex;
    align-items: center;

    img {
      max-height: 30px;
      margin-right: 0.5em;
      padding-bottom: 0.22em;

      &:hover,
      &:focus,
      &:active {
        padding-bottom: 0.07em;
        border-bottom: 0.15em solid colours.$accent-a;
      }
    }
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
      margin: 0;

      &:first-child {
        margin-bottom: 1em;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  scroll-margin-top: 0.5em;
}
